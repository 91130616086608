import { RegistrationPageBase, type AdditionalRegistrationFormConfig } from "./RegistrationPageBase"
import { type RegistrationFormDetail, type RegistrationFormDetailWithValues } from "@services/registrations/registrationFormDetail"
import { ServerCall, ResponseType, type ServerResponse } from "@infrastructure/api/serverCall"
import webApi from "../webApi"
import { type Ctx } from "@jaredpalmer/after"
import { initialProps404 } from "@infrastructure/afterjs/initialPropsResults"
import { initialPropsWrapper } from "@infrastructure/afterjs/initialPropsWrapper"
import { evaluateOnplaceRegistration, SubraceStatusEvaluation } from "common"
import { UnreachableError } from "utils"
import { routes } from "../routes"
import { type RegistrationSuccess } from "reg-utils"
import { type ServerValidationErrors } from "@services/registrations/validation/serverValidationError"
import { type FormSubmit } from "./FormSubmit"
import { Component } from "react"
import { type SubRaceSlug } from "common"
import Layout from "@website/layout/Layout/Layout"
import Heading from "../components/Heading/Heading"
import Container from "@website/components/Container/Container"
import Meta from "../layout/Meta"
import { OnPlaceRedirectForm } from "./OnPlaceRedirectForm"
import { useHistory } from "react-router"
import { SubRaceHeader } from "@website/components/SubRaceHeader/SubRaceHeader"

interface Props {
  response?: ServerResponse<RegistrationFormDetailWithValues, unknown>
  slugMissing: boolean
}

export default class RegistrationPage extends Component<Props> {
  static getInitialProps = initialPropsWrapper(async (params: Ctx<any>) => {
    const subraceSlug: string | undefined = params.match.params.subraceSlug
    if (!subraceSlug) {
      return { slugMissing: true }
    }
    const response = await ServerCall.call<SubRaceSlug, RegistrationFormDetail>(webApi.registration.onplaceFormDetail, { slug: subraceSlug })
    if (response.type === ResponseType.notFound) {
      return initialProps404
    }
    return { response, slugMissing: false }
  })

  render() {
    if (this.props.slugMissing) {
      return <MissingSlugPage />
    }

    const additionalFormConfig: AdditionalRegistrationFormConfig = {
      submitData: (data, detail) =>
        ServerCall.call<FormSubmit, RegistrationSuccess, { errors: ServerValidationErrors }>(webApi.registration.submitForm, { ...data, type: detail.type, subRaceId: detail.subraceid }),
      redirectPathAfterSubmit: ({ guid }) => routes.onPlaceConfirm.getLink(guid),
      getItemDisabled: () => false,
      eshopMode: { enabled: false, readOnly: false, allowUnavailable: false },
      submitButtonTitle: "Registrovat se",
      submitButtonTitleWithExistingEmail: "Registrovat se na stejný email",
      showTotalPrice: true
    }

    return (
      <RegistrationPageBase
        response={this.props.response}
        title={detail => `Registrace na místě na ${detail.racename} - ${detail.subracename}`}
        header={detail => <SubRaceHeader title="Registrace na místě na" raceName={detail.racename} raceSlug={detail.raceslug} subraceName={detail.subracename} showSelfEditPromo />}
        validateSubraceStatus={this.validateSubraceStatus}
        config={additionalFormConfig}
      />
    )
  }

  private validateSubraceStatus = (detail: RegistrationFormDetail) => {
    const evaluation = evaluateOnplaceRegistration(detail.racestatus, detail.status)
    switch (evaluation) {
      case SubraceStatusEvaluation.TooEarly:
        return `Registrace na místě na závod ${detail.racename} / ${detail.subracename} není zatím možná.`
      case SubraceStatusEvaluation.TooLate:
        return `Registrace na závod ${detail.racename} / ${detail.subracename} jsou už vypnuté.`
      case SubraceStatusEvaluation.Allowed:
        return undefined // registration allowed
      default:
        throw new UnreachableError(evaluation)
    }
  }
}

const MissingSlugPage = () => {
  const history = useHistory()
  return (
    <Layout>
      <Meta title="Registrace na místě" noIndex />
      <Container>
        <Heading type="h1">Registrace na místě</Heading>
        <OnPlaceRedirectForm
          onSubmit={subrace => {
            history.push(routes.onPlaceRegistration.getLink(String(subrace.id)))
          }}
        />
      </Container>
    </Layout>
  )
}
