import { Formik, Field, type FieldProps } from "formik"
import { Input } from "./form/inputs/InputBase"
import { ErrorMessage } from "./form/inputs/ErrorMessage"
import Label from "../components/Label"
import { yup } from "utils/translated-yup"
import { Button } from "@website/components/Button/Button"

interface Props {
  onSubmit: (subraceId: SubraceId) => void
}

interface SubraceId {
  id: number
}

const subraceIdSchema = yup.object<SubraceId>().shape({
  id: yup.number().integer("Zadejte číslo závodu").required("Zadejte číslo závodu")
})

export const OnPlaceRedirectForm: React.FC<Props> = ({ onSubmit }) => (
  <Formik<Partial<SubraceId>> initialValues={{}} onSubmit={data => onSubmit(data as SubraceId)} validationSchema={subraceIdSchema}>
    {formikProps => (
      <form onSubmit={formikProps.handleSubmit}>
        <div className="flex flex-wrap">
          <Label className="w-full" htmlFor="id" isRequired={true}>
            Zadejte číslo závodu
          </Label>
          <Field
            name="id"
            render={(renderProps: FieldProps<string, SubraceId>) => (
              <Input {...renderProps.field} type="number" hasError={!!formikProps.touched.id && !!formikProps.errors.id} className="flex-1 mr-auto h-auto" />
            )}
          />
          <Button type="submit">Otevřít</Button>
          <ErrorMessage className="w-full" touched={formikProps.touched.id} error={formikProps.errors.id} />
        </div>
      </form>
    )}
  </Formik>
)
